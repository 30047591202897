import axios from "axios";

const BASE_URL = window.location.origin + "/auth/";

class AuthService {
  login(user) {
    //    const fakeUser = { username: "deka", password: "", accessToken: "-" };
    //   localStorage.setItem("user", JSON.stringify(fakeUser));

    //return Promise.resolve(fakeUser);

    console.log(user);

    return axios
      .post(BASE_URL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
